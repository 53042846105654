import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../dkui/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/login/login"),
    meta: {
      title: "404",
      hidden: true,
    },
    children: []
  },

  {
    path: "/",
    name: "Root",
    component: Layout,
    redirect: "/order/orderList",
    meta: {
      title: "首页",
      hidden: true
    },
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/test/test"),
        meta: {
          title: "测试",
          hidden: true,
        },
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    component: Layout,
    meta: {
      title: "订单",
    },
    children: [
      {
        path: "orderList",
        name: "orderList",
        component: () => import("@/views/order/orderList"),
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "orderDetail",
        name: "orderDetail",
        component: () => import("@/views/order/orderDetail"),
        meta: {
          title: "订单详情",
          hidden: true,
        },
      },
      {
        path: "confirmOrderList",
        name: "confirmOrderList",
        component: () => import("@/views/order/confirmOrderList"),
        meta: {
          title: "收货管理",
        },
      },
      {
        path: "orderTransfer",
        name: "orderTransfer",
        component: () => import("@/views/order/orderTransfer"),
        meta: {
          title: "订单转账"
        }
      }
    ],
  },
  {
    path: "/goods",
    name: "goods",
    component: Layout,
    meta: {
      title: "商品",
    },
    children: [
      {
        path: "goodsList",
        name: "goodsList",
        component: () => import("@/views/goods/index"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "typelist",
        name: "typelist",
        component: () => import("@/views/commoditytype/typelist"),
        meta: {
          title: "商品类型",
        },
      },
      {
        path: "attributelist",
        name: "attributelist",
        component: () => import("@/views/commoditytype/attributelist"),
        meta: {
          hidden: true,
          title: "属性列表",
        },
      },
      {
        path: "parameterlist",
        name: "parameterlist",
        component: () => import("@/views/commoditytype/parameterlist"),
        meta: {
          hidden: true,
          title: "参数列表",
        },
      },
      {
        path: "editGoods",
        name: "EditGoods",
        component: () => import("@/views/goods/editGoods"),
        meta: {
          title: "商品编辑",
          hidden: true,
          noKeepAlive: true
        },
      },
      {
        path: "goodsCategories",
        name: "goodsCategories",
        component: () => import("@/views/goods/goodsCategories"),
        meta: {
          title: "商品分类",
        },
      },
      // {
      //   path: "setWords",
      //   name: "setWords",
      //   component: () => import("@/views/tools/setWords"),
      //   meta: {
      //     title: "商品埋词",
      //   },
      // },
      {
        path: "setGoodsBase",
        name: "setGoodsBase",
        component: () => import("@/views/tools/setGoodsBase"),
        meta: {
          title: "批量修改基本信息",
        },
      },
      {
        path: "setGoodsDetail",
        name: "setGoodsDetail",
        component: () => import("@/views/tools/setGoodsDetail"),
        meta: {
          title: "批量修改详情",
        },
      },
      {
        path: "goodsUpload",
        name: "goodsUpload",
        component: () => import("@/views/goods/goodsUpload"),
        meta: {
          title: "导入商品",
        },
      },
      // {
      //   path: "setGoodsTime",
      //   name: "setGoodsTime",
      //   component: () => import("@/views/tools/setGoodsTime"),
      //   meta: {
      //     title: "批量上下架",
      //   },
      // },
      // {
      //   path: "setGoodsPic",
      //   name: "setGoodsPic",
      //   component: () => import("@/views/tools/setGoodsPic"),
      //   meta: {
      //     title: "批量修改图片",
      //   },
      // },
      {
        path: "transferClassification",
        name: "transferClassification",
        component: () => import("@/views/tools/transferClassification"),
        meta: {
          title: "修改分类",
        },
      },
      {
        path: "setLot",
        name: "setLot",
        component: () => import("@/views/tools/setLot"),
        meta: {
          title: "批量修改",
          hidden: true,
        },
      },
      {
        path: "setOnce",
        name: "setOnce",
        component: () => import("@/views/tools/setOnce"),
        meta: {
          title: "逐一修改",
          hidden: true,
        },
      },
      {
        path: "browseRecords",
        name: "browseRecords",
        component: () => import("@/views/goods/browseRecords"),
        meta: {
          hidden: true,
          title: "浏览记录",
        },
      },
      {
        path: "commodityAudit",
        name: "commodityAudit",
        component: () => import("@/views/goods/commodityAudit"),
        meta: {
          title: "商品审核",
        },
      },
      {
        path: "commodityRecycleBin",
        name: "commodityRecycleBin",
        component: () => import("@/views/goods/commodityRecycleBin"),
        meta: {
          title: "商品回收站",
        },
      },
      {
        path: "commodityEvaluation",
        name: "commodityEvaluation",
        component: () => import("@/views/goods/commodityEvaluation"),
        meta: {
          title: "商品评论",
        },
      },
      {
        path: "goodsReview",
        name: "goodsReview",
        component: () => import("@/views/goods/goodsReview"),
        meta: {
          title: "评论详情",
          hidden: true,
        },
      },
      {
        path: "brand",
        name: "brand",
        component: () => import("@/views/goods/brand"),
        meta: {
          title: "品牌",
        },
      },
      // {
      //   path:"salesSetup/salesSetup",
      //   name:"salesSetup",
      //   component:()=>import('@/views/globalSettings/salesSetup'),
      //   meta:{
      //     title: "销售设置"
      //   }
      // },
    ],
  },
  {
    path: "/shipping",
    name: "shipping",
    component: Layout,
    meta: {
      title: "发货",
    },
    children: [
      {
        path: "shippingList",
        name: "shippingList",
        component: () => import("@/views/shipping/shippingList"),
        meta: {
          title: "发货管理",
        },
      },
      {
        path: "distributionList",
        name: "distributionList",
        component: () => import("@/views/shipping/distributionList"),
        meta: {
          title: "配货单管理",
        },
      },
      // {
      //   path: "arrivalReminder",
      //   name: "arrivalReminder",
      //   component: () => import("@/views/order/arrivalReminder"),
      //   meta: {
      //     title: "到货提醒",
      //   },
      // },
      // {
      //   path: "arrivalReminderReport",
      //   name: "arrivalReminderReport",
      //   component: () => import("@/views/order/arrivalReminderReport"),
      //   meta: {
      //     hidden: true,
      //     title: "到货提醒报表",
      //   },
      // },
      // {
      //   path: "deliveryPoint",
      //   name: "deliveryPoint",
      //   component: () => import("@/views/deliveryPoint"),
      //   meta: {
      //     title: "发货点设置",
      //   },
      // },
    ],
  },
  {
    path: "/afterSale",
    name: "afterSale",
    component: Layout,
    meta: {
      title: "售后",
    },
    children: [
      {
        path: "afterSaleList",
        name: "afterSaleList",
        component: () => import("@/views/afterSale/afterSaleList"),
        meta: {
          title: "售后管理",
        },
      },
      {
        path: "afterSale",
        name: "afterSale",
        component: () => import("@/views/afterSale/afterSale"),
        meta: {
          title: "新增售后",
          hidden: true,
        },
      },
      {
        path: "afterSaleDetail",
        name: "afterSaleDetail",
        component: () => import("@/views/afterSale/afterSaleDetail"),
        meta: {
          title: "售后详情",
          hidden: true,
        },
      },
      {
        path: "returnAndExchange",
        name: "returnAndExchange",
        component: () => import("@/views/returnAndExchange"),
        meta: {
          title: "退换货原因类型",
        },
      },
      {
        path: "refundsList",
        name: "refundsList",
        component: () => import("@/views/afterSale/refundsList"),
        meta: {
          title: "退款管理",
        },
      },
    ],
  },
  {
    path: "/inquiry",
    name: "inquiry",
    component: Layout,
    meta: {
      title: "询盘",
    },
    children: [
      {
        path: "inquiry",
        name: "inquiry",
        component: () => import("@/views/inquiry/inquiry"),
        meta: {
          title: "询盘",
        },
      },
      {
        path: "inquiryDetail",
        name: "inquiryDetail",
        component: () => import("@/views/inquiry/inquiryDetail"),
        meta: {
          title: "询盘详情",
          hidden: true,
        },
      },
      // {
      //   path:"createQuotation",
      //   name:"createQuotation",
      //   component:()=>import('@/views/inquiry/createQuotation'),
      //   meta:{
      //     title: "创建报价单",
      //     hidden:true
      //   }
      // },
      {
        path: "inquiryCreateOrder",
        name: "inquiryCreateOrder",
        component: () => import("@/views/inquiry/inquiryCreateOrder"),
        meta: {
          title: "新建订单",
          hidden: true,
        },
      },
      {
        path: "link",
        name: "link",
        component: () => import("@/views/link"),
        meta: {
          title: "链接",
        },
      },
      {
        path: "replyTemplate",
        name: "replyTemplate",
        component: () => import("@/views/replyTemplate"),
        meta: {
          title: "回复模板",
        },
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/globalSettings"),
        meta: {
          title: "询盘设置",
        },
      },
    ],
  },
  {
    path:'/blog',
    name: 'blog',
    component: Layout,
    meta: {
      title: "BLOG"
    },
    children: [
      {
        path: "blogList",
        name: "blogList",
        component: () => import("@/views/blog/BlogList"),
        meta: {
          title: "BLOG列表",
        },
      },
      {
        path: "blogCate",
        name: "blogCate",
        component: () => import("@/views/blog/BlogCate"),
        meta: {
          title: "BLOG分类列表",
        },
      },
      {
        path: "release",
        name: "release",
        component: () => import("@/views/blog/release"),
        meta: {
          title: "发布",
          hidden: true,
          noKeepAlive: true
        },
      },
    ]
  },
  {
    path:'/article',
    name: 'article',
    component: Layout,
    meta: {
      title: "文章"
    },
    children: [
      {
        path: "articleList",
        name: "articleList",
        component: () => import("@/views/article/articleList"),
        meta: {
          title: "文章列表",
        },
      },
      {
        path: "articleCate",
        name: "articleCate",
        component: () => import("@/views/article/articleCate"),
        meta: {
          title: "文章分类列表",
        },
      },
      {
        path: "release",
        name: "release",
        component: () => import("@/views/article/release"),
        meta: {
          title: "发布",
          hidden: true,
          noKeepAlive: true
        },
      },
    ]
  },
  {
    path: "/wholesale",
    name: "wholesale",
    component: Layout,
    meta: {
      title: "批发",
    },
    children: [
      {
        path: "wholesaleList",
        name: "wholesaleList",
        component: () => import("@/views/wholesale/index"),
        meta: {
          title: "单品列表",
        },
      },
      {
        path: "wholesale",
        name: "wholesale",
        component: () => import("@/views/wholesale/wholesale"),
        meta: {
          title: "单品批发",
          hidden: true
        },
      },
      {
        path: "globalWholesale",
        name: "globalWholesale",
        component: () => import("@/views/wholesale/globalWholesale"),
        meta: {
          title: "全局",
        },
      },
      {
        path: "cateWholesale",
        name: "cateWholesale",
        component: () => import("@/views/wholesale/cateWholesale"),
        meta: {
          title: "分类",
        },
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: Layout,
    meta: {
      title: "用户",
    },
    children: [
      {
        path: "userList",
        name: "userList",
        component: () => import("@/views/user/list"),
        meta: {
          title: "用户列表",
        },
      },
      {
        path: "userDetails",
        name: "userDetails",
        component: () => import("@/views/user/userDetails"),
        meta: {
          hidden: true,
          title: "用户详情",
        },
      },
      {
        path: "userLevel",
        name: "userLevel",
        component: () => import("@/views/user/userLevel"),
        meta: {
          title: "用户等级",
        },
      }
    ],
  },
  {
    path: "/coupon",
    name: "coupon",
    component: Layout,
    meta: {
      title: "优惠券",
    },
    children: [
      {
        path: "coupon",
        name: "coupon",
        component: () => import("@/views/coupon"),
        meta: {
          title: "优惠券",
        },
      },
      {
        path: "editorcoupons",
        name: "editorcoupons",
        component: () => import("@/views/commoditytype/editorcoupons"),
        meta: {
          hidden: true,
          title: "编辑优惠券",
        },
      },
      {
        path: "receiptOfCoupons",
        name: "receiptOfCoupons",
        component: () => import("@/views/coupon/receiptOfCoupons"),
        meta: {
          hidden: true,
          title: "领取情况",
        },
      },
      {
        path: "reviewCoupons",
        name: "reviewCoupons",
        component: () => import("@/views/coupon/reviewCoupons"),
        meta: {
          title: "审核优惠券",
        },
      },
    ],
  },
  // {
  //   path: "/BOM",
  //   name: "BOM",
  //   component: Layout,
  //   meta: {
  //     title: "BOM",
  //   },
  //   children: [
  //     {
  //       path: "bom",
  //       name: "bom",
  //       component: () => import("@/views/bom"),
  //       meta: {
  //         title: "BOM",
  //       },
  //     },
  //     {
  //       path: "tagList",
  //       name: "tagList",
  //       component: () => import("@/views/bom/tagList"),
  //       meta: {
  //         title: "标签列表",
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "/ip",
  //   name: "ip",
  //   component: Layout,
  //   meta: {
  //     title: "IP",
  //   },
  //   children: [
  //     {
  //       path: "shieldIP",
  //       name: "shieldIP",
  //       component: () => import("@/views/shield/shieldIP"),
  //       meta: {
  //         title: "屏蔽IP",
  //       },
  //     },
  //     {
  //       path: "accessPassword",
  //       name: "accessPassword",
  //       component: () => import("@/views/shield/accessPassword"),
  //       meta: {
  //         title: "访问密码",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/internationality",
    name: "internationality",
    component: Layout,
    meta: {
      title: "国际",
    },
    children: [
      {
        path: "multiCurrency",
        name: "multiCurrency",
        component: () => import("@/views/multiCurrency"),
        meta: {
          title: "多币种",
        },
      },
      {
        path: "multiCurrency/languagelist",
        name: "languagelist",
        component: () => import("@/views/multiCurrency/languagelist"),
        meta: {
          title: "多语言",
        },
      },
      {
        path: "multiCurrency/translationlist",
        name: "translationlist",
        component: () => import("@/views/multiCurrency/translationlist"),
        meta: {
          hidden: true,
          title: "翻译列表",
        },
      },
      {
        path: "multiCurrency/ratelist",
        name: "ratelist",
        component: () => import("@/views/multiCurrency/ratelist"),
        meta: {
          title: "税率列表",
        },
      },
    ],
  },
  {
    path: "/logistics",
    name: "logistics",
    component: Layout,
    meta: {
      title: "物流",
    },
    children: [
      {
        path: "logistics/setlogistics",
        name: "setlogistics",
        component: () => import("@/views/logistics/setlogistics"),
        meta: {
          title: "设置页面",
        },
      },
      {
        path: "logistics/courierDemoList",
        name: "courierDemoList",
        component: () => import("@/views/logistics/courierDemoList"),
        meta: {
          title: "运费模板列表",
        },
      },
      {
        path: "logistics/freightDemo",
        name: "freightDemo",
        component: () => import("@/views/logistics/freightDemo"),
        meta: {
          hidden: true,
          title: "运费模板",
        },
      },
      {
        path: "regionalManagement",
        name: "regionalManagement",
        component: () => import("@/views/regionalManagement"),
        meta: {
          title: "区域管理",
        },
      },
      {
        path: "areaLabel",
        name: "areaLabel",
        component: () => import("@/views/logistics/areaLabel"),
        meta: {
          title: "区域标签",
        },
      },
      {
        path: "countrySettings",
        name: "countrySettings",
        component: () => import("@/views/logistics/countrySettings"),
        meta: {
          title: "国家设置",
        },
      },
      {
        path: "courierCompany",
        name: "courierCompany",
        component: () => import("@/views/logistics/courierCompany"),
        meta: {
          title: "快递公司",
        },
      },
    ],
  },
  {
    path: "/advertise",
    name: "advertise",
    component: Layout,
    meta: {
      title: "广告",
    },
    children: [
      {
        path: "advertisement",
        name: "advertisement",
        component: () => import("@/views/advertisement/index"),
        meta: {
          title: "广告",
        },
      },
      {
        path: "advertisementDetail",
        name: "advertisementDetail",
        component: () => import("@/views/advertisement/advertisementDetail"),
        meta: {
          title: "广告详情",
          hidden: true
        },
      },
    ],
  },
  {
    path: "/maildirectory",
    name: "maildirectory",
    component: Layout,
    meta: {
      title: "邮件",
    },
    children: [
      {
        path: "index",
        name: "maildirectory",
        component: () => import("@/views/maildirectory/index"),
        meta: {
          title: "邮件模板",
        },
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: Layout,
    meta: {
      title: "设置",
    },
    children: [
      {
        path: "payment",
        name: "payment",
        component: () => import("@/views/payment/index"),
        meta: {
          title: "支付设置",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
