import { get, post } from './http'

// AI重写
export const apiAIRewrite = p => post('tools/ai/rewrite',p)


//登录
export const apiLogin = p => post('admin/admin/login',p)
//获取分类列表
export const apiGetCategoryList = p => get('goods/category/category-list', p)
//添加或修改分类
export const apiAddOrUpdateCategory = p => post('goods/category/add-or-update-category', p)
//获取上级分类
export const apiGetParentCategory = p => get('goods/category/category-parent-list', p)
//删除分类
export const apiDelCategory = p => get('goods/category/del', p)
//获取区域标签列表
export const apiGetAreaList = p => get('expressage/config/area-label-get', p)
//获取文件目录列表
export const apiGetMenuList = p => get('base/fs/list-folder', p)
//获取文件列表
export const apiGetFileList = p => get('base/fs/list-file', p)
//添加文件夹
export const apiAddFolder = p => post('base/fs/add-folder', p)
//删除文件夹
export const apiDeleteFolder = p => post('base/fs/delete-folder', p)
//重命名文件夹
export const apiRenameFolder = p => post('base/fs/rename-folder', p)
//移动文件夹
export const apiMoveFolder = p => post('base/fs/move-folder', p)
//文件改名
export const apiRenameFile = p => post('base/fs/update-file-name', p)
//删除文件
export const apiDeleteFile = p => post('base/fs/delete-file', p)
//移动文件
export const apiMoveFile = p => post('base/fs/move-file', p)
//搜索
export const apiSearch = p => get('base/fs/search-file', p)
//编辑商品用选项
export const apiGetGoodsOptions = p => get('goods/category/all-goods-category-list', p)
//获取sku列表
export const apiGetSkuList = p => get('goods/goods-type/sku-list', p)
//保存商品信息
export const apiSaveGoods = p => post('goods/goods/mod', p)
//获取商品信息
export const apiGetGoodsInfo = p => get('goods/goods/get-goods', p)
//运费模板列表
export const apiGetFreightList = p => get('expressage/config/list', p)
//获取商品参数模板列表
export const apiGetGoodsParamList = p => get('goods/goods-type/param-list', p)
//添加临时商品
export const apiAddTempGoods = p => post('goods/goods/add-temp-goods', p)
//获取用户列表
export const apiGetUserList = p => post('user/user/user-list', p)
//导出用户
export const apiGetUsersExcel = p => post('excel/user/export-user', p)
//查看用户详情
export const apiGetUserInfo = p => post('user/user/user-info', p)
//修改用户信息
export const apiUpdateUserInfo = p => post('user/user/update-user-info', p)
//获取用户等级列表
export const apiGetUserLevel = p => post('user/user-level/level-list', p)
//新增/修改用户等级
export const apiUpdateUserLevel = p => post('user/user-level/add-or-update', p)
//删除用户等级
export const apiDeleteUserLevel = p => post('user/user-level/del-level', p)
//获取国家设置
export const apiGetCountrysList = p => post('expressage/config/country-get', p)
//修改国家营业状态
export const apiCountryIsBiz = p => post('expressage/config/country-is-biz',p)
//新增、修改国家设置
export const apiUpdateCountry = p => post('expressage/config/country-add-or-update', p)
//删除国家设置
export const apiDeleteCountry = p => post('expressage/config/country-del', p)
//获取区域管理
export const apiGetAreaMsg = p => post('expressage/config/region-get', p)
//获取区域标签
export const apiGetAreaLabel = p => post('expressage/config/area-label-get', p)
//获取区域下拉框数据
export const apiGetAreaSelect = p => post('expressage/config/city-list', p)
//新增、修改区域标签
export const apiUpdateAreaLabel = p => post('expressage/config/area-label-add-or-update', p)
//删除区域标签
export const apiDeleteAreaLabel = p => post('expressage/config/area-label-del', p)
//获取快递商列表
export const apiGetCourierList = p => post('expressage/config/logi-list', p)
//获取用户等级(不分页)
export const apiGetUserLevelList = p => post('user/user/user-level-list', p)
//获取用户等级
export const apiGetLevelList = p => post('user/user-level/id-name', p)
//获取快递模板列表
export const apiGetCourierlList = p => post('expressage/config/express-template-list', p)
//新增、修改快递模板
export const apiUpdateCourierDemo = p => post('expressage/config/set-or-update-express-template', p)
//获取快递模板详情
export const apiGetCourierInfo = p => post('expressage/config/express-template-info', p)
//获取全局配置
export const apiGetGlobalConfiguration = p => post('expressage/config/get-express-config', p)
//新增、修改快递配置
export const apiSetConfiguration = p => post('expressage/config/add-or-update', p)
//限时秒杀场次列表
export const apiGetSessionList = p => post('marketing/seckill/seckill-session-list', p)
//新增、修改秒杀场次
export const apiUpdateSession = p => post('marketing/seckill/seckill-session-add-or-update', p)
//删除秒杀场次
export const apiDeleteSession = p => post('marketing/seckill/seckill-session-del', p)
//新增、修改限时秒杀
export const apiUpdateSeckill = p => post('marketing/seckill/seckill-add-or-update', p)
//秒杀活动详情
export const apiSeckillInfo = p => post('marketing/seckill/seckill-details', p)
//获取限时秒杀列表
export const apiGetSeckillList = p => post('marketing/seckill/seckill-list', p)
//查看活动商品
export const apiGetSeckillGoods = p => post('marketing/seckill/seckill-goods-list', p)
//修改活动商品
export const apiUpdateSeckillGoods = p => post('marketing/seckill/seckill-goods-update', p)
//删除活动商品
export const apiDeleteSeckillGoods = p => post('marketing/seckill/seckill-goods-del', p)
//新增、修改满额包邮
export const apiUpdatePackageMail = p => post('marketing/exemption/exemption-add-or-update', p)
//获取满额包邮列表
export const apiGetPackageMail = p => post('marketing/exemption/exemption-list', p)
//删除包邮列表
export const apiDeletePackageMail = p => post('marketing/exemption/exemption-del', p)
//满额包邮全局配置
export const apiSetGlobalPackage = p => post('marketing/exemption/exemption-config-change', p)
//新增、修改砍价活动
export const apiUpdateBargaining = p => post('marketing/bargain-combo/bargain-add-or-update', p)
//获取活动详情-修改使用
export const apiGetBargainingInfo = p => post('marketing/bargain-combo/bargain-details', p)
//获取砍价商品列表
export const apiGetBargainingGoodsList = p => post('marketing/bargain-combo/bargain-list', p)
//删除砍价商品
export const apiDeleteBargainingGoods = p => post('marketing/bargain-combo/bargain-goods-del', p)
//获取砍价列表
export const apiGetBargainingList = p => post('marketing/bargain-combo/bargain-join-list', p)
//获取帮砍详情
export const apiGetHelpList = p => post('marketing/bargain-combo/bargain-log-list', p)
//获取砍价商品全局配置
export const apiGetGoodsConfig = p => post('marketing/bargain-combo/bargain-config-get', p)
//修改砍价商品全局配置
export const apiUpdateGoodsConfig = p => post('marketing/bargain-combo/bargain-config-change', p)
//新增、修改组合套餐
export const apiUpdateGoodsPackage = p => post('marketing/discount-package/discount-package-add-or-update', p)
//获取组合套餐列表
export const apiGetPackageList = p => post('marketing/discount-package/discount-package-list', p)
//获取套餐详情
export const apiGetPackageInfo = p => post('marketing/discount-package/discount-package-details', p)
//删除套餐
export const apiDeletePackages = p => post('marketing/discount-package/discount-package-del', p)
//新增、修改折扣活动
export const apiGetDiscount = p => post('marketing/discount/discount-package-add-or-update', p)
//获取折扣活动列表
export const apiGetDiscountList = p => post('marketing/discount/discount-list', p)
//查看活动商品
export const apiGetDiscountGoods = p => post('marketing/discount/discount-goods-list', p)
//删除折扣活动商品
export const apiDeleteDiscountGoods = p => post('marketing/discount/discount-goods-del', p)
//新增、修改团购活动
export const apiUpdateBulkActivity = p => post('marketing/group-purchase/group-purchase-add-or-update', p)
//获取团购商品列表
export const apiGetBulkList = p => post('marketing/group-purchase/group-purchase-list', p)
//获取团购详情（修改使用）
export const apiGetBulkInfo = p => post('marketing/group-purchase/group-purchase-details', p)
//删除团购商品
export const apiDeleteBulkGoods = p => post('marketing/group-purchase/goods-del', p)
//新增、修改预售活动
export const apiUpdateBookingGoods = p => post('marketing/presell/presell-add-or-update', p)
//获取预售活动列表
export const apiGetBookingGoodsList = p => post('marketing/presell/presell-list', p)
//预售活动详情
export const apiGetBookingInfo = p => post('marketing/presell/presell-details', p)
//删除预售商品
export const apiDeleteBookingList = p => post('marketing/presell/goods-del', p)
//新增、修改优惠券
export const apiUpdateCoupon = p => post('marketing/coupon/coupon-add-or-update', p)
//获取优惠券列表
export const apiGetCouponList = p => post('marketing/coupon/coupon-get-list', p)
//获取优惠券详情
export const apiGetCouponInfo = p => post('marketing/coupon/coupon-details', p)
//审核优惠券
export const apiAuditCoupon = p => post('marketing/coupon/coupon-audit', p)
//优惠券领取情况
export const apiGetCouponUser = p => post('marketing/coupon/coupon-get-log', p)
//删除优惠券
export const apiDeleteCoupon = p => post('marketing/coupon/del', p)
//获取优惠券(不分页)
export const apiGetNotPageCoupon = p => post('marketing/coupon/get-list', p)
//新增、修改充值套餐
export const apiUpdateTopPackage = p => post('marketing/recharge-combo/recharge-combo-add-or-update', p)
//获取充值套餐列表
export const apiGetTopPackageList = p => post('marketing/recharge-combo/recharge-combo-list-get', p)
//获取充值套餐详情
export const apiGetTopPackageInfo = p => post('marketing/recharge-combo/recharge-combo-details', p)
//开启关闭套餐活动模块
export const apiSetTopPackage = p => post('marketing/recharge-combo/recharge-combo-config-change', p)
//删除充值奖励
export const apiDeleteTopPackage = p => post('marketing/recharge-combo/recharge-combo-del', p)
//充值奖励历史记录
export const apiGetTopPackageLog = p => post('marketing/recharge-combo/recharge-combo-log', p)
//设置消费奖励
export const apiSetConsumerIncentives = p => post('marketing/consume-award/consume-award-config', p)
//获取消费奖励详情
export const apiGetConsumerInfo = p => post('marketing/consume-award/consume-award-config-get', p)
//获取奖励用户列表
export const apiGetConsumerUsers = p => post('marketing/consume-award/get-activity-log', p)
//新增、修改快递公司列表
export const apiOptionCourierMerchants = p => post('expressage/config/courier-services-company', p)
//获取快递公司列表
export const apiGetCourierMerchants = p => post('expressage/config/courier-services-company-list', p)
//删除快递公司
export const apiDelCourierMerchants = p => post('expressage/config/courier-services-company-del', p)
//新增、修改专题活动
export const apiOperationProject = p => post('marketing/specia-activity/special-activity-add-or-update', p)
//获取专题活动列表
export const apiGetProjectActivities = p => post('marketing/specia-activity/special-activity-list', p)
//获取专题活动详情
export const apiGetProjectInfo = p => post('marketing/specia-activity/special-activity-details', p)
//删除专题活动
export const apiDelProjectActivities = p => post('marketing/specia-activity/special-activity-goods-del', p)
//新增、修改先付后返
export const apiOperationPayment = p => post('marketing/cashback/add', p)
//获取先付后返列表
export const apiGetPaymentList = p => post('marketing/cashback/list', p)
//获取活动详情
export const apiGetPaymentMessage = p => post('marketing/cashback/details', p)
//获取先付后返活动用户
export const apiGetPaymentUsers = p => post('marketing/cashback/user-log', p)
//获取先付后返参与列表
export const apiGetParticipationList = p => post('marketing/cashback/participation', p)
//冻结先付后返用户资格
export const apiOperationFreezeState = p => post('marketing/cashback/freeze', p)
//先付后返用户执行返现
export const apiOperationCashbackState = p => post('marketing/cashback/cashback', p)
//新增、修改批发商品
export const apiOperationWholesale = p => post('marketing/wholesale/wholesale-add-or-update', p)
//获取全局批发列表
export const apiGetGlobalWholesale = p => post('marketing/wholesale/get-global', p)
//获取批发商品列表
export const apiGetWholesaleList = p => post('marketing/wholesale/wholesale-list', p)
//删除批发商品
export const apiDelWholesaleList = p => post('marketing/wholesale/wholesale-del', p)
//新增语种
export const apiAddLanguages = p => post('international/translate/add', p)
//获取语种列表
export const apiGetLanguagesList = p => post('international/translate/list', p)
//获取缺省语言
export const apiGetLanguagesConfig = p => post('international/translate/config-get', p)
//设置缺省语言
export const apiSetLanguagesConfig = p => post('international/translate/config', p)
//导入语言文件
export const apiImportLanguageFile = p => post('international/excel/import', p)
//导出多语言
export const apiGetLanguageExcel = p => post('international/excel/derive', p)
//生成代码文件
export const apiBuildCode = p => post('international/translate/gen', p)
//关键字列表
export const apiGetKeywordList = p => post('international/translate/keyword-list', p)
//新增关键字
export const apiAddKeywordList = p => post('international/translate/keyword-add', p)
//翻译关键字
export const apiGetTranslateDescr = p => post('international/translate/translate', p)
//保存关键字翻译
export const apiSaveTranslateDescr = p => post('international/translate/save', p)
//删除多语言
export const apiDeleteLanguageMsg = p => post('international/translate/del', p)
//新增币种
export const apiAddCurrencyType = p => post('international/coin/coin-add', p)
//获取币种列表
export const apiGetCurrencyTypeList = p => post('international/coin/list', p)
//删除币种
export const apiDelCurrencyType = p => post('international/coin/coin-del', p)
//获取币种配置
export const apiGetCurrencyConfig = p => post('international/coin/coin-conf-get', p)
//修改多币种配置
export const apiUpdateCurrencyConfig = p => post('international/coin/coin-config', p)
//新增、修改税率
export const apiOperationRate = p => post('international/tax-rate/add', p)
//获取税率列表
export const apiGetRateList = p => post('international/tax-rate/list', p)
//删除税率数据
export const apiDeleteRateList = p => post('international/tax-rate/del', p)
//获取已设置批发价分类
export const apiGetSetCate = p => post('marketing/wholesale/get-cate',p)
//获取未设置批发价分类
export const apiGetUnuseCate = p => post('marketing/wholesale/unuse-category-list',p)
//删除批发价分类
export const apiDelSetCate = p => post('marketing/wholesale/del-cate',p)

//获取广告列表
export const apiGetAD = p => post('base/ad/list',p)
//新增编辑广告
export const apiCreateUpdateAD = p => post('base/ad/add-or-update',p)
//删除广告
export const apiDeleteAD = p => post('base/ad/del-ad',p)
//获取广告详情
export const apiGetADDetail = p => post('base/ad/get-ad',p)
//删除广告链接
export const apiDelADDetail = p => post('base/ad/del-Link',p)
//保存广告链接
export const apiEditADDetail = p => post('base/ad/add-or-update-link',p)

//保存导入
export const apiSaveUpload = p => post('goods/tool/import-handle',p)
//查询订单物流
export const apiGetOrderLogiTrack = p => post('order/order/get-logi-track',p)
//查询待发货物流
export const apiGetWaitingShip = p => post('order/order/waiting-for-ship-list',p)
//发货
export const apiOrderShip = p => post('order/order/shipped',p)
//待打印配货单
export const apiWaitPrintOrder = p => post('order/order/waiting-for-print-list',p)
//打印配货单
export const apiPrintOrder = p => post('order/order/print',p)
//导出待发货快递单
export const apiExportShippingOrder = p => post('order/order/export-waiting-for-ship-list',p)
//保存待发货快递单导入
export const apiSaveShippingOrder = p => post('order/order/import-handle',p)
//获取邮件模板列表
export const apiGetEmailTemplateList = p => post('base/email-template/list',p)
//获取邮件模板
export const apiGetEmailTemplate = p => post('base/email-template/get',p)
//更新邮件模板
export const apiUpdateEmailTemplate = p => post('base/email-template/update',p)


// //获取用户列表
// export const apiGetUserList = p => post('user/user/user-list', p)
//查看用户详情
// export const apiGetUserInfo = p => post('user/user/user-info', p)
//获取用户等级列表
// export const apiGetUserLevel = p => post('user/user-level/level-list', p)
//新增/修改用户等级
// export const apiUpdateUserLevel = p => post('user/user-level/add-or-update', p)
//删除用户等级
// export const apiDeleteUserLevel = p => post('user/user-level/del-level', p)
//获取国家设置
// export const apiGetCountryList = p => post('expressage/config/country-get', p)
//新增、修改国家设置
// export const apiUpdateCountry = p => post('expressage/config/country-add-or-update', p)
//删除国家设置
// export const apiDeleteCountry = p => post('expressage/config/country-del', p)
//获取区域管理
// export const apiGetAreaMsg = p => post('expressage/config/region-get', p)
//获取区域标签
// export const apiGetAreaLabel = p => post('expressage/config/area-label-get', p)
//获取区域下拉框数据
// export const apiGetAreaSelect = p => post('expressage/config/city-list', p)
//新增、修改区域标签
// export const apiUpdateAreaLabel = p => post('expressage/config/area-label-add-or-update', p)
//删除区域标签
// export const apiDeleteAreaLabel = p => post('expressage/config/area-label-del', p)
//获取快递商列表
// export const apiGetCourierList = p => post('expressage/config/logi-list', p)
//获取用户等级(不分页)
// export const apiGetUserLevelList = p => post('user/user/user-level-list', p)
//获取快递模板列表
// export const apiGetCourierlList = p => post('expressage/config/express-template-list', p)
//新增、修改快递模板
// export const apiUpdateCourierDemo = p => post('expressage/config/set-or-update-express-template', p)
//获取快递模板详情
// export const apiGetCourierInfo = p => post('expressage/config/express-template-info', p)
//获取全局配置
// export const apiGetGlobalConfiguration = p => post('expressage/config/get-express-config', p)
//新增、修改快递配置
// export const apiSetConfiguration = p => post('expressage/config/add-or-update', p)
//限时秒杀场次列表
// export const apiGetSessionList = p => post('marketing/seckill/seckill-session-list', p)
//新增、修改秒杀场次
// export const apiUpdateSession = p => post('marketing/seckill/seckill-session-add-or-update', p)
//删除秒杀场次
// export const apiDeleteSession = p => post('marketing/seckill/seckill-session-del', p)

//获取商品类型列表
export const apiGetGoodsCategoryList = p => get('goods/goods-type/category-list-get', p)
//创建商品类型
export const apiCreateGoodsCategory = p => post('goods/goods-type/category-add',p)
//删除商品类型
export const apiDelGoodsCategory = p => post('goods/goods-type/category-del',p)
//获取商品-属性-参数列表
export const apiGetGoodsPropertyList = p => get('goods/goods-type/goods-property-list', p)
//创建商品-属性-参数
export const apiCreateGoodsProperty = p => post('goods/goods-type/goods-property-add-or-update',p)
//获取商品参数详情
export const apiGetGoodsPropertyDetails = p => get('goods/goods-type/goods-property-details', p)
//删除商品-属性-参数
export const apiDelGoodsProperty = p => post('goods/goods-type/category-property-del',p)
//获取商品通用参数
export const apiGetGoodsParams = p => get('goods/goods/get-params',p)


//商品列表
export const apiGetGoodsList = p => post('goods/goods/list', p)
//删除商品
export const apiDeleteGoods = p => post('goods/goods/del', p)
//立即上下架
export const apiChangeGoodsList = p => post('goods/goods/is-list', p)
//克隆商品
export const apiCloneGoods = p => post('goods/goods/clone', p)
//商品选择器(SKU商品)
export const apiGetGoodsChooseSKU = p => get('goods/choose', p)
//商品选择器(商品)
export const apiGetGoodsChooseGoods = p => get('goods/choose/goods', p)
//商品选择器 by SKU
export const apiGetGoodsChooseBySKU = p => post('goods/choose/list-by-sku', p)
//商品埋词
export const apiGoodsSetWord = p => post('goods/tool/set-word', p)
//商品逐一修改基本信息
export const apiModfiyGoodsBaseOnce = p => post('goods/tool/mod-base-by-sku-id', p)
//商品批量修改详情
export const apiModfiyGoodsDetail = p => post('goods/tool/mod-descr', p)
//商品批量修改上下架时间
export const apiModfiyGoodsList = p => post('goods/tool/take-on-or-down', p)
//修改分类
export const apiMoveCategory = p => post('goods/tool/move-category', p)
//商品审核
export const apiGoodsAudit = p => post('goods/goods-audit', p)
//商品还原
export const apiGoodsTrash = p => post('goods/tool/trash', p)
//商品评论列表
export const apiGetCommentList = p => post('base/comment/list', p)
//商品评论删除
export const apiDeleteComment = p => post('base/comment/del', p)
//商品评论隐藏
export const apiHideComment = p => post('base/comment/hide', p)
//商品评论详情
export const apiGetCommentDetail = p => get('base/comment/get', p)
//商品评论回复
export const apiReplyComment = p => post('base/comment/reply', p)
//品牌列表
export const apiGetBrandList = p => post('goods/goods-brand/list', p)
//品牌添加
export const apiAddBrand = p => post('goods/goods-brand/add-or-mod', p)
//品牌删除
export const apiDeleteBrand = p => post('goods/goods-brand/del', p)
//重新生成品牌首字母
export const apiRebuildBrand = p => post('goods/goods-brand/re-gen-first-char', p)
//获取国家列表
export const apiGetCountryList = p => post('base/region/country', p)
//获取区域列表
export const apiGetRegionList = p => get('base/region/region-list', p)
//订单搜索条件混合
export const apiGetOrderMix = p => post('order/order/mix', p)
//订单列表
export const apiGetOrderList = p => post('order/order/list', p)
//订单审核列表
export const apiOrderAudit = p => post('order/order/voucher-list-for-audit', p)
//删除订单转账记录
export const apiDelVoucher = p => post('order/order/del-voucher', p)
//订单详情
export const apiGetOrderDetail = p => get('order/order/get', p)
//获取订单转账记录
export const apiGetOrderVoucher = p => post('order/order/get-voucher',p)
//确认订单转账
export const apiApproveVoucher = p => post('order/order/approve-voucher',p)
//订单备注
export const apiSetOrderRemark = p => post('order/order/get-or-set-remark', p)
//修改订单地址
export const apiModifyOrderAddress = p => post('order/order/mod-addr', p)
//取消订单
export const apiCancelOrder = p => post('order/order/cancel-order', p)
//调整订单价格
export const apiAdjustOrderPrice = p => post('order/order/mod-price', p)
//调整订单支付
export const apiAdjustOrderPay = p => post('order/order/set-payment-state', p)
//发送订单Invoice
export const apiSendOrderInvoice = p => post('order/order/send-invoice', p)
//发送订单Email
export const apiSendOrderEmail = p => post('order/order/send-email', p)
//订单收货
export const apiReceiveOrder = p => post('order/order/set-recived', p)

//退货原因类型列表
export const apiGetReturnReasonTypeList = p => get('order/support/reason', p)
//新增修改退货原因
export const apiAddReturnReason = p => post('order/support/add-or-mod-reason', p)
//修改退货原因状态
export const apiModifyReturnReason = p => post('order/support/trigger-reason', p)
//删除退货原因
export const apiDeleteReturnReason = p => post('order/support/del-reason', p)

//售后列表
export const apiGetSupportList = p => post('order/support/list', p)
//获取售后搜索条件混合
export const apiGetSupportMix = p => post('order/support/mix', p)
//新增售后
export const apiAddSupport = p => post('order/support/create', p)
//搜索订单列表
export const apiGetOrderChooseList = p => post('order/choose/list', p)
//获取售后详情
export const apiGetSupportDetail = p => get('order/support/get', p)
//同意售后
export const apiAcceptSupport = p => post('order/support/accept', p)
//拒绝售后
export const apiRejectSupport = p => post('order/support/reject', p)
//卖家已收货
export const apiSellerReceived = p => post('order/support/seller-get-goods', p)
//卖家未收货
export const apiSellerNotReceived = p => post('order/support/seller-unget-goods', p)
//关闭售后
export const apiCloseSupport = p => post('order/support/close', p)
//添加卖家备注
export const apiAddSellerRemark = p => post('order/support/add-seller-notes', p)

//获取用户地址列表
export const apiGetAddressList = p => post('user/user/address-list', p)

//获取询盘列表
export const apiGetInquiryList = p => post('rfq/inquiry/list',p)
//询盘详情
export const apiGetInquiryDetail = p => get('rfq/inquiry/get',p)
//询盘回复
export const apiReplyInquiry = p => post('rfq/inquiry/reply-by-admin',p)
// 快捷回复模板列表
export const apiGetReplyTemplate = p => get('rfq/quick/list-template',p)
// 添加/修改快捷回复模板
export const apiAddReplyTemplate = p => post('rfq/quick/add-or-set-template',p)
//快捷链接列表
export const apiGetQuickLinkList = p => post('rfq/quick/list-link',p)
//添加/修改快捷链接
export const apiAddQuickLink = p => post('rfq/quick/add-or-set-link',p)
//删除快捷链接
export const apiDelQuickLink = p => post('rfq/quick/del-link',p)
//删除回复模板
export const apiDelReplyTemplate = p => post('rfq/quick/del-template',p)
//创建报价单
export const apiCreateQuote = p => post('rfq/quotation/add-or-mod',p)
//获取物流公司列表
export const apiGetExpressList = p => get('rfq/quotation/logi',p)
//获取报价单列表
export const apiGetQuoteList = p => post('rfq/quotation/list',p)
//获取报价单HTML
export const apiGetQuoteHtml = p => get('rfq/quotation/get-html',p)
//询盘邮件设置
export const apiSetInquiryEmail = p => post('rfq/config/set',p)
//获取询盘邮件设置
export const apiGetInquiryEmail = p => get('rfq/config/get',p)
//创建订单
export const apiCreateOrder = p => post('rfq/order/add',p)
//获取系统设置的付款开关列表
export const apiGetPaywayList = p => post('payment/payment/switch-list',p)
//获取某付款方式配置条目
export const apiGetPaywayDetail = p => post('payment/payment/detail',p)
//设置具体值
export const apiSetPayway = p => post('payment/payment/batch-set',p)
//获取自动通过审核的开关
export const apiGetAutoAudit = p => post('marketing/coupon/auto-audit',p)
//设置自动通过审核的开关
export const apiSetAutoAudit = p => post('marketing/coupon/update-audit',p)



//文章列表
export const apiGetArticleList = p => post('cms/docs/list',p)
//新增或修改文章
export const apiCreateOrUpdateArticle = p => post('cms/docs/add-or-update',p)
//获取文章详情
export const apiGetArticle = p => post('cms/docs/get',p)
//删除文章
export const apiDelArticle = p => post('cms/docs/del',p)
//文章分类列表
export const apiGetArticleCategory = p => post('cms/docs/category-list',p)
//删除分类
export const apiDelDocCategory = p => post('cms/docs/del-category',p)
//新增或修改分类
export const apiCreateOrUpdateCategory = p => post('cms/docs/add-or-update-category',p)

//BLOG列表
export const apiGetBLOGList = p => post('cms/blog/list',p)
//新增或修改BLOG
export const apiCreateOrUpdateBLOG = p => post('cms/blog/add-or-update',p)
//获取BLOG详情
export const apiGetBLOG = p => post('cms/blog/get',p)
//删除BLOG
export const apiDelBLOG = p => post('cms/blog/del',p)
//BLOG分类列表
export const apiGetBLOGCategory = p => post('cms/blog/category-list',p)
//删除分类
export const apiDelBLOGCategory = p => post('cms/blog/del-category',p)
//新增或修改分类
export const apiCreateOrUpdateBLOGCategory = p => post('cms/blog/add-or-update-category',p)


//售后退款(Paypal)
export const apiRefundPaypal = p => post('order/support/refund-paypal',p)
//售后退款
export const apiRefund = p => post('order/support/refund',p)
//售后退款审核列表
export const apiGetRefundList = p => post('order/refund/list',p)
//变更退款状态
export const apiChangeRefundState = p => post('order/refund/change-state',p)
//售后回复
export const apiAfterSaleReply = p => post('order/support/reply-chat',p)

